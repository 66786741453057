.myBtn {
  display: flex;
  padding: 0.4rem 0.6rem 0.4rem 1.6rem;
  border-radius: 4px;
  background-color: #1f3041;
  outline: none;
  cursor: pointer;
  color: #fff;
  transition: all 0.5s ease;
  justify-content: center;
  align-items: center;
  gap: 1em;
  border: none;
  font-size: 18px;
}

.myBtn:focus {
  outline: none;
}

.myBtn .icon {
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.myBtn .icon span {
  background: white;
  border-radius: 100%;
  padding: 0rem;
  width: 0rem;
  height: 0rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transform: rotate(-180deg);
  transition: all 0.5s ease;
}

.myBtn:hover .icon span {
  transform: rotate(45deg);
  border-radius: 100%;
  padding: 0.5rem;
  width: 1.7rem;
  height: 1.7rem;
}
.myBtn:disabled {
  background-color: rgba(31, 48, 65, 0.5);
  cursor: not-allowed;
  pointer-events: none; 
}