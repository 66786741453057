body {
  font-family: Arial, Helvetica, sans-serif;
}

.pointer {
  cursor: pointer;
}

.react-select {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 100;
}

.form-control {
  font-family: Arial, Helvetica, sans-serif;
  color: black;
}

.logo-box {
  display: flex;
  flex-direction: column;
}

.compact-centered {
  margin: auto;
  width: 500px;
}

.float-right {
  float: right;
}

#success-message-container {
  position: relative;
}

/* this only works if the position of the parent container is relative, as in #success-message-container */
.float-bottom {
  margin-top: 30px;
  position: relative;
  bottom: 10px;
}

.word-wrap {
  inline-size: 95%;
  overflow-wrap: break-word;
}

.center-text {
  text-align: center;
}

.clickable {
  cursor: pointer;
}

.ReactTable .rt-thead .rt-th:last-child {
  text-align: left;
}

.data-table {
  font-size: small;
}

.rt-tbody .rt-tr {
  height: 30px;
}

.rt-tbody .rt-tr:hover {
  min-height: 30px;
  height: fit-content;
}

.ReactTable .rt-td:hover {
  text-overflow: clip;
  overflow: visible;
  display: inline-block;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: normal;
  line-break: strict;
  hyphens: auto;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  white-space: normal;
}

.ReactTable .rt-td {
  padding: 0 7px;
}

.form-modal {
  display: unset;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 1050;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  -webkit-animation-name: fadeIn; /* Fade in the background */
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s;
}

.form-modal-content {
  position: fixed;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  -webkit-animation-name: slideIn;
  -webkit-animation-duration: 0.4s;
  animation-name: slideIn;
  animation-duration: 0.4s;
}

.form-modal-body {
  max-height: 500px;
  min-height: 200px;
  overflow: auto;
  border: solid lightgray;
  border-width: 2px 0;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 5px;
}

.form-button-spinner {
  height: 20px;
  width: 20px;
}

.form-button {
  margin-right: 5px !important;
  height: 40px;
  width: 100px;
}

.title-button {
  margin: 0;
  font-size: 12px;
  height: 50px;
  width: 250px;
}

.modal-footer {
  justify-content: center;
}

.label-with-button {
  display: flex;
  overflow: visible;
}

.additional-info {
  position: relative;
  width: fit-content;
  padding: 0;
  margin: 0;
  margin-left: 2px;
}
.additional-info:hover {
  cursor: pointer;
  overflow: visible;
}

.speech-bubble {
  position: absolute;
  background: rgb(230, 247, 255);
  border-radius: 0.4em;
  border: 2px solid #007bff;
  width: 800px;
  color: black;
  left: -350%;
  top: 150%;
  padding: 10px;
  text-align: left;
  z-index: 5;
}

.speech-bubble:after {
  content: '';
  position: absolute;
  top: 0;
  left: 5%;
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-bottom-color: #007bff;
  border-top: 0;
  margin-left: -9px;
  margin-top: -9px;
}

.disabled-grey {
  background-color: #c5c5c5;
}

.required {
  border: 2px solid red;
  background: rgb(255, 232, 230);
  border-bottom-color: red;
}

.required:after {
  border-bottom-color: red;
}

.warning-message {
  color: red;
}

.caution-message {
  color: #eed202;
}

.caution {
  border: 2px solid #eed202;
  background: #fff9cc;
  border-bottom-color: #eed202;
}

.caution:after {
  border-bottom-color: #eed202;
}

.loading-spinner {
  display: flex;
  justify-content: center;
}

.react-select.primary .react-select__control .react-select__value-container--has-value .react-select__single-value,
.react-select.primary .react-select__control .react-select__value-container--has-value .react-select__multi-value__label {
  color: black;
}

.input-textMatch {
  width: 400px;
}

.environmentHeader {
  color: rgb(141, 33, 19);
  font-weight: bold;
}

.environmentHeaderBar {
  background-color: rgb(253, 216, 93);
}

.greenStatus {
  color: green;
}

.redStatus {
  color: red;
}

.running-job-tab {
  font-size: large;
  cursor: pointer;
}

.client-selector-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.client-selector-label {
  width: 5%;
}

.client-selector-select {
  width: 75%;
  margin-right: 5px;
}

.disabled-client-selector-select {
  width: 75%;
  margin-right: 5px;
  background-color: rgb(197, 197, 197);
}

.client-selector-buttons {
  width: 20%;
}

.pull-right {
  float: right;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes slideIn {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}
