.contentCard {
    margin: 0 auto;
    width: 80%;
}

.listGroup {
    margin-bottom: 50px;
}

.listGroupItemText {
    font-size: 18px;
}